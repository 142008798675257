import { useEffect } from "react";
import { useState } from "react";
import Card from "./Card";
import {ethers} from "ethers";


function Nfts(props) {
  const [nfts, setNfts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    await fetch("https://eth-mainnet.g.alchemy.com/v2/ZqzGDy1dOuF4Gnf2-EdFNtVKOBb-Ed5o/getNFTs/?owner="+props.account)
      .then(response => response.json())
      .then(data => {
        setNfts(data.ownedNfts);
        
      }).then(setLoading(false));
  }, [props.account]);

  return (
    <div className="bg-white bg-opacity-70 my-4 mx-2 md:mx-10 rounded-md md:p-6">
      <h1 className="text-4xl p-4 text-center">NFTs</h1>
      <div className="flex flex-wrap mx-4 justify-center">
        {!loading && nfts.map((item, key) => {
            return (
              <Card
              pk={props.pk}
              rpc={props.rpc}
              key={key}
              title={item.metadata.name}
              imageUrl={item.media[0].thumbnail}
              id={ethers.toNumber(item.id.tokenId)}
              contract={item.contract.address}
            />
            );
     

        })}
      </div>



    </div>
  );
}

export default Nfts;
