import { useState } from "react";
import QrReader from "react-qr-reader";
import CryptoJS from "crypto-js";
import md5 from "md5";


function Login(props) {

  const [qrData, setQrData] = useState();
  const [camera, setCamera] = useState();
  const [start, setStart] = useState();
  const [pinInput, setPinInput] = useState();
  const [pinValue, setPinValue] = useState();
  const [message, setMessage] = useState();

  const decrypt = (cipherText, key, id, hash) => {
    let data = md5(key + id, "utf-8");
    let genHash = data.slice(0, 16);
    const decryptedPk = CryptoJS.AES.decrypt(cipherText, genHash).toString(
      CryptoJS.enc.Utf8,
    );
    const decryptedId = CryptoJS.AES.decrypt(hash, genHash).toString(
      CryptoJS.enc.Utf8,
    );

    if (id + key == decryptedId) {
      return decryptedPk;
    }

    return false;

    // Buffer.concat([cipher.update(cipherText), cipher.final()]).toString("utf8");
  };

  const handleError = async (data) => {
    console.log("error", data);
  };
  const handleScan = async (data) => {
    if (data) {
      setStart(false);
      setQrData(data);
      setPinInput(true);
    }
  };

  const handleLogin = () => {
    setMessage();
    let data = JSON.parse(qrData);
    let pk = decrypt(data.k, pinValue, data.i, data.h);
    if (pk == false) {
      setMessage("Neteisingas PIN kodas!");
      return;
    }
    props.setPk(pk);

  };

  return (
    <div className="flex justify-center items-center h-screen">
      {/* <div class="grid grid-cols-1"> */}
      <div className="w-3/4 md:w-1/2 lg:w-1/3 bg-white shadow-md rounded-md overflow-hidden">
        <div className="p-4 flex justify-center items-center">
          {!start && !qrData && <div className=""><h2 className="text-lg font-medium mb-4 text-center">Prisijungti su kortele</h2>
            <button onClick={() => setStart(1)}
              className="w-full bg-emerald-400 hover:bg-emerald-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
              Pradėti
            </button></div>}
          {start &&
            <div className="min-w-2/3 w-full">
              <p className="text-[12px] text-[#5B546F]">Pasirinkti kamerą</p>

              <select
                name="categories"
                className="text-[#18224C] rounded-lg  focus:outline-none cursor-pointer border-2 mb-3"
                onChange={(e) => setCamera(e.target.value)}
              >
                <option
                  value="environment"
                  key={1}
                  className="option"
                  defaultValue
                >
                  Galinė
                </option>
                <option value="user" key={2} className="option">
                  Priekinė
                </option>
              </select>

              <QrReader
                facingMode={camera}
                delay={100}
                onError={handleError}
                onScan={handleScan}
                className=""
              />
            </div>}
          {pinInput &&
            <div className="flex items-center">
              <input
                className="px-3 py-2 border rounded-l"
                type="password"
                onChange={(event) => setPinValue(event.target.value)}
                placeholder="Įveskite PIN kodą"
              />
              <button
                className="px-4 py-2 bg-emerald-500 text-white rounded-r ml-1"
                onClick={handleLogin}
                onSubmit={handleLogin}
              >
                Prisijungti
              </button>
            </div>
          }
        {message}
        </div>
      </div>
    </div>
    // </div>

  );
}

export default Login;
