import QRCode from "react-qr-code";
import { ethers } from 'ethers';
import { useState } from "react";


function Wallet2(props) {

  const [recipientAddress, setRecipientAddress] = useState();
  const [amount, setAmount] = useState();
  const [message, setMesaage] = useState();



  const sendTx = async () => {
    setMesaage();
    const provider = new ethers.JsonRpcProvider(props.rpc);
    const wallet = new ethers.Wallet(props.pk, provider);
    const txCount = await wallet.provider.getTransactionCount(wallet.address);
    const feeData = await provider.getFeeData()
    const gasLimit = 21000;
    const transaction = {
      to: ethers.getAddress(recipientAddress),
      value: ethers.parseEther(amount),
      // nonce: txCount + 1,
      chainId: 1,
      gasLimit: gasLimit,
      gasPrice: feeData.maxFeePerGas

    };
    setMesaage("Vykdoma! Prašokite palaukti...");

    const txResponse = await wallet.sendTransaction(transaction);
    await txResponse.wait(1);
    const receipt = await provider.getTransactionReceipt(txResponse.hash);
    setMesaage("Transakcijos unikalus kodas: "+ txResponse.hash);
    props.fetchData(props.pk);


  };

  return (
    <div className="flex justify-center items-center my-5 mx-2 md:mx-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="max-w-md rounded overflow-hidden shadow-lg p-4 bg-emerald-600 bg-neutral-800 bg-opacity-40">
          {/* <img className="w-full" src="/img/card-top.jpg" alt="Sunset in the mountains"/> */}
          <div className="px-6 py-4">
            <div className="font-bold text-3xl mb-6 text-white">Piniginės balansas</div>
            <div className="flex">
              <div className="w-1/2 pr-4">
                <p className="text-2xl text-white">ETH</p>
              </div>
              <div className="w-1/2 mr-4">
                <p onClick={()=>props.fetchData(props.pk)} className="text-2xl text-white text-right">{props.balance}</p>
              </div>
            </div>

          </div>
          <div className="px-6 pt-9 pb-2">
            <div className="text-sm text-white px-4">Gavėjo adresas:</div>
            <input
              onChange={(e) => setRecipientAddress(e.target.value)}
              type="text"
              className="w-full h-10 mb-2 px-4 rounded-full border-white-400 border-2 focus:outline-none focus:border-emerald-100" placeholder="0x00726183671263...." />
            <div className="text-sm text-white px-4">Suma:</div>
            <input
              onChange={(e) => setAmount(e.target.value)}
              type="text"
              className="w-full h-10 mb-2 px-4 rounded-full border-white-400 border-2 focus:outline-none focus:border-emerald-100" placeholder="0.01" />
            <p className="text-center text-white">{message}</p>
            <button onClick={sendTx} className="inline-block bg-lime-300 w-full text-center rounded-full px-6 py-2 mt-6 text-xl font-semibold text-gray-700 mr-2 mb-2">Siųsti</button>
          </div>
        </div>

        <div className="max-w-md rounded overflow-hidden shadow-lg bg-white p-4 bg-opacity-40">
          <div className="px-6 py-4">
            <div className="font-bold text-3xl mb-2">Gauti</div>
            <div className="text-sm">Piniginės adresas: <span className="inline-block border-2 outline-white rounded-full px-4 text-sm font-semibold text-gray-700 ml-6">kopijuoti</span></div>
            <p className="text-gray-700 text-md">
              {props.account}
            </p>
          </div>
          <div style={{ height: "auto", margin: "0 auto", maxWidth: 256, width: "100%" }} className="px-6 pt-4 pb-6">
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={props.account}
              viewBox={`0 0 256 256`}
              bgColor={"#1e1e1e00"}
            />
          </div>
        </div>
      </div>
    </div >
  );
}

export default Wallet2;
