import { useState } from "react";
import { ethers } from "ethers";

function Card({ title, imageUrl, id, contract, pk, rpc }) {
    const [openInput, setOpenInput] = useState(false);
    const [toAddress, setToAddress] = useState();
    const ABI = [
        {
            inputs: [
                {
                    internalType: "address",
                    name: "from",
                    type: "address",
                },
                {
                    internalType: "address",
                    name: "to",
                    type: "address",
                },
                {
                    internalType: "uint256",
                    name: "tokenId",
                    type: "uint256",
                },
            ],
            name: "safeTransferFrom",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },

    ];


    const transfer = async () => {
        const provider = new ethers.JsonRpcProvider(rpc);
        const signer = new ethers.Wallet(pk, provider);

        const cont = new ethers.Contract(contract, ABI, signer);
        const tx = await cont.safeTransferFrom(signer.address, toAddress, id);
        await tx.wait();
        setOpenInput(false);


    };
    return (
        <div className="w-1/1 sm:w-1/2 md:w-1/3 lg:w-1/5 px-4 mb-8">
            <div className="bg-white shadow-md rounded-md overflow-hidden">
                <img src={imageUrl} alt="Card Image" className="w-full h-48 object-cover" />
                <div className="p-4">
                    <h2 className="text-lg font-medium mb-2">{title}</h2>
                    <h2 className="text-lg font-medium mb-2">{id}</h2>

                    <p className="text-sm text-gray-500 break-all px-2">Kontraktas: <b>{contract}</b></p>
                    {openInput ? <>
                        <div className="text-sm text-gray mt-4 px-4">Naujo sąvininko adresas:</div>

                        <input
                            onChange={(e) => setToAddress(e.target.value)}
                            type="text"
                            className="w-full h-10 mb-2 px-4 rounded-full border-white-400 border-2 focus:outline-none focus:border-emerald-100" placeholder="0x00726183671263...." />
                        <button onClick={() => transfer()} className="inline-block bg-blue-300 w-full text-center rounded-full px-6 py-2 mt-3 text-xl font-semibold text-gray-700 mr-1 mb-1">Persiųsti</button>
                        <button onClick={() => setOpenInput(false)} className="inline-block bg-red-300 w-full text-center rounded-full px-6 py-2 mt-3 text-xl font-semibold text-gray-700 mr-1 mb-1">Nutraukti</button>
                    </> :
                        <button onClick={() => setOpenInput(true)} className="inline-block bg-lime-300 w-full text-center rounded-full px-6 py-2 mt-3 text-xl font-semibold text-gray-700 mr-1 mb-1">Siųsti</button>
                    }
                </div>

            </div>
        </div>
    );
}

export default Card;



