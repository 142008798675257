import { useState } from "react";
import {ethers} from "ethers";

function Sign(props) {
  const [message, setMessage] = useState();
  const [signature, setSignature] = useState();

  const signMessage = async () => {
    const signer = new ethers.Wallet(props.pk);
    const signature = await signer.signMessage(message);
    setSignature(signature);

  };

  return (
    <div className="bg-white bg-opacity-70 mx-2 md:mx-10 rounded-md p-6 mb-6">
      <h1 className="text-4xl p-4 text-center">Psirašymas</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <h2 className="text-lg font-medium mb-2">Pridėkite tekstą kurį norite pasirašyti</h2>
          <textarea onChange={(e) => setMessage(e.target.value)} className="w-full h-32 px-4 py-2 rounded-lg border-gray-400 border-2 focus:outline-none focus:border-blue-500 " rows="3" placeholder="Tekstas...."></textarea>
          <button onClick={() => signMessage()} className="inline-block bg-lime-300 w-full text-center rounded-full px-6 py-2 mt-6 text-xl font-semibold text-gray-700">Pasirašyti žinutę</button>

        </div>
        <div>
          <h2 className="text-lg font-medium mb-2 px-6">Parašas</h2>
          {!message && <p className="text-md text-gray-500 px-6">Pasirašykite pranešimą/tekstą naudodami savo asmeninį raktą. Pasirašymo metu sukuriamas skaitmeninis pranešimo parašas, kuris gali būti naudojamas pranešimo autentiškumui patikrinti. Šis parašas sukuriamas naudojant matematinį algoritmą, ir yra unikalus jūsų privačiam piniginės raktui, todėl niekas kitas negali sugeneruoti to paties parašo tam pačiam pranešimui.</p>}
          {signature && message && <p className="text-lg font-bold break-all px-6">{signature}</p>}
          
          
        </div>
      </div>

    </div>
  );
}

export default Sign;
