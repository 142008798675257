
import './App.css';
import Header from './Componnents/Header'
import Wallet2 from './Componnents/Wallet'
import Nfts from './Componnents/Nfts'
import Sign from './Componnents/Sign'
import Login from './Componnents/Login';
import { useEffect, useState } from 'react';
import { ethers } from "ethers";


function App() {

  const [pk, setPk] = useState();
  const [account, setAccount] = useState(1);
  const [balance, setBalance] = useState();


  const rpcEndpoint = 'https://mainnet.infura.io/v3/2a84ecfa46674658a733cda1b06e57b0';

  const fetchData = async (pk) => {
    const wallet = new ethers.Wallet(pk);
    const account = await wallet.getAddress();
    const provider = new ethers.JsonRpcProvider(rpcEndpoint);
    const balance = await provider.getBalance(account);
    setBalance(ethers.formatEther(balance));
    setAccount(account);
  }

  useEffect(() => {
    if (pk) {
      fetchData(pk);
    }
  }, [pk]);

  return (
    <div className="body-bg min-h-screen">
      <Header pk={pk}></Header>
      {!pk ?
        <Login setPk={setPk}></Login>
        :
        <>
          <Wallet2 account={account} balance={balance} pk={pk} fetchData={fetchData} rpc={rpcEndpoint}></Wallet2>
          <Nfts pk={pk} rpc={rpcEndpoint} account={account}></Nfts>
          <Sign pk={pk}></Sign>
          <div></div>
        </>

      }


    </div>
  );
}


export default App;
